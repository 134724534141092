const app = require('./app');
const ui = require('./ui');

const blank = () => ({ health: 100 });
// const wood = () => ({ type: 'wood', maxHealth: 100, health: Math.floor(Math.random() * 50) + 50 });
const wood = () => ({ type: 'wood', maxHealth: 100, health: 100 });
const stone = () => ({ type: 'stone', maxHealth: 350, health: 350 });
const tower = (i = {}) => ({ type: 'tower', maxHealth: 25, health: i.health || Math.floor(Math.random() * 25) + 1 });

app.setWorld([
  [blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank()],
  [blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank()],
  [blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank()],
  [blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank()],
  [tower(), tower(), tower(), blank(), tower({ health: 5 }), tower({ health: 2 }), tower({ health: 5 }), blank(), tower(), tower(), tower(), tower(), tower(), tower(), tower(), tower(), tower(), tower(), tower(), tower(), tower(), tower(), tower(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank()],
  [wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank()],
  [blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank()],
  [blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank()],
  [blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank()],
  [blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank(), blank()],
  [wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood(), wood()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone(), stone(), blank(), blank(), blank(), wood(), blank(), blank(), blank(), blank(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()],
  [stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone(), stone()]
]);

document.addEventListener('DOMContentLoaded', function () {
  ui(app, document.body);

  window.state = app.state;
});
