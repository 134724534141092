require('dragscroll');
const h = require('mithril');

const propertyPanel = require('./propertyPanel');

module.exports = function (app, container) {
  let downCoords;

  function renderCell (item) {
    return h('div', {
      style: item.maxHealth ? '--data-health: ' + (item.health / item.maxHealth) : '',
      className: `${item.maxHealth && item.health !== item.maxHealth ? 'has-health' : ''} cell cell-${item.type || 'blank'} ${item.selected ? 'cell-selected' : ''}`,
      onmousedown: function (event) {
        downCoords = [event.screenX, event.screenY];
      },
      onmouseup: function (event) {
        if (event.screenX === downCoords[0] && event.screenY === downCoords[1]) {
          app.setSelectedCell(item);
        }
      }
    });
  }

  function render () {
    const ui = h('div',
      h('div', {
        class: 'world dragscroll'
      },
      app.state.world.map(row => {
        return h('div', { class: 'row' }, row.map(item => renderCell(item)));
      })
      ),

      propertyPanel(app),

      h('div', { class: 'actionBar' },
        h('button', {
          style: { marginTop: '10px' },
          onclick: () => app.tick()
        }, 'Next Tick'),

        h('button', {
          style: { marginTop: '10px' },
          onclick: () => app.tick(10)
        }, 'Next Tick (x10)'),

        h('button', {
          style: { marginTop: '10px' },
          onclick: () => app.tick(50)
        }, 'Next Tick (x50)')
      )
    );

    h.render(container, ui);
  }

  app.setRender(render);

  render();
};
