const rules = require('./rules');

const state = {
  world: [],
  rules
};

let render;

function setRender (fn) {
  render = fn;
}

function setWorld (worldData) {
  worldData = worldData
    .map((row, rowIdx) => row
      .map((cell, colIdx) => ({
        ...cell,
        row: rowIdx,
        col: colIdx
      })));

  state.world = worldData;

  render && render();
}

function setSelectedCell (cell) {
  if (cell.selected) {
    delete cell.selected;
  } else {
    cell.selected = !cell.selected;
  }

  render && render();
}

function tick (cycles = 1) {
  const startTime = new Date().getTime();

  for (let x = 0; x < cycles; x++) {
    state.world = state.world.map(row => {
      return row.map(cell => {
        return state.rules
          .reduce((acc, rule) => {
            acc = rule(acc, state);
            return acc;
          }, cell);
      });
    });
  }

  const endTime = new Date().getTime();

  console.log(`${cycles} ticks took: ${endTime - startTime}ms`);

  render && render();
}

setInterval(tick, 1000);

module.exports = {
  state,

  setRender,
  setWorld,
  setSelectedCell,

  tick
};
