const h = require('mithril');

function propertyPanel (app) {
  return h('div', { className: 'panel' },
    h('div', { className: 'panel-header' }, 'Cell Inspector'),
    h('div', { className: 'panel-content' },
      h('h2', 'Selected'),
      h('pre',
        app.state.world
          .flat()
          .filter(cell => cell.selected)
          .map(cell => ({
            top: cell.row > 0 && app.state.world[cell.row - 1][cell.col],
            right: cell.col < app.state.world[0].length && app.state.world[cell.row][cell.col + 1],
            bottom: cell.row < app.state.world[0].length && app.state.world[cell.row + 1][cell.col],
            left: cell.col > 0 && app.state.world[cell.row][cell.col - 1],
            ...cell
          }))
          .map(cell => JSON.stringify(cell, null, 2))
          .join('\n\n')
      ),
      h('h2', 'Rules'),
      h('pre', app.state.rules.join('\n\n'))
    )
  );
}

module.exports = propertyPanel;
